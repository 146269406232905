const DELIMITER = '/%';

const Utils = {
  scrollToTop(smooth = true) {
    this.scrollTo('#app', smooth);
  },
  scrollTo(id, smooth = true) {
    const el = document.querySelector(id);
    if (el) el.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
  },
  scrollToAll(query, smooth = true) {
    document.querySelectorAll(query)
      .forEach((el) => el.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' }));
  },
  formatDate(date) {
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  },
  exportToCSV(json, formKeys, questionKeys, answerKeys) {
    const formCsv = [];
    const questionCsv = [];
    const answersCsv = [];

    // Clés
    // Reponses
    answersCsv.push(answerKeys.join(DELIMITER));
    // Formulaires
    formCsv.push(formKeys.join(DELIMITER));
    // Questions
    questionCsv.push(questionKeys.join(DELIMITER));

    // Lignes
    json.forEach((row) => {
      // Formulaires
      const formId = row.form.id;
      formCsv.push([
        formId,
        row.form.description,
        row.form.label,
        row.form.article,
      ].join(DELIMITER));

      row.componentAnswers.forEach((componentAnswer) => {
        // Questions
        const questionId = componentAnswer.component.id;
        questionCsv.push([
          questionId,
          componentAnswer.component.position,
          componentAnswer.component.type,
          componentAnswer.component.description,
          componentAnswer.component.label,
          componentAnswer.component.variable,
        ].join(DELIMITER));

        componentAnswer.answers.forEach((answer) => {
          // Réponses
          // eslint-disable-next-line no-underscore-dangle
          let finalAnswer = '';
          const { accountId, weekNumber } = answer;
          try {
            finalAnswer = answer.value;
            // eslint-disable-next-line no-empty
          } catch (e) {}

          const date = new Date(answer.answeredAt);

          // Date Formatting options
          const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
          };

          // Si la question était un QCM, on concatène les réponses
          if (componentAnswer.component.type === 'MULTIPLE_CHOICE_QUESTION') {
            const { choices } = answer;
            try {
              choices.push(answer.others);
              finalAnswer = choices.join(DELIMITER);
              // eslint-disable-next-line no-empty
            } catch (e) {}
            if (answer.none) {
              finalAnswer = '-';
            }
          }

          // Si la réponse est 'NOT_KNOWN' ou 'NOT_ANSWERED'
          if (answer.type === 'NOT_KNOWN_ANSWER') {
            finalAnswer = '(?)';
          }
          if (answer.type === 'SKIPPED_ANSWER') {
            finalAnswer = '-';
          }

          answersCsv.push([
            accountId,
            formId,
            questionId,
            weekNumber !== null ? weekNumber : '-',
            date.toLocaleString('fr-CH', options),
            finalAnswer,
          ].join(DELIMITER));
        });
      });
    });

    return [answersCsv.join('\n'), formCsv.join('\n'), questionCsv.join('\n')];
  },
};

export default Utils;
