<template>
  <v-col class="pa-0">
    <v-list style="width: 100%">
      <v-scroll-x-transition group>
      <v-list-item
        v-for="(choice, i) in choices" :key="keys[i]"
        dense class="pr-0"
      >
        <v-list-item-content>
          <v-text-field
            dense
            class="pa-0 ma-0"
            :value="choices[i]"
            @input="updateChoice(i, $event)"
            @change="updateChoice(i, $event)"
          >
            <template v-slot:append-outer>
              <v-btn
                :disabled="i <= 0"
                icon color="primary"
                @click="swapChoices(i, i - 1)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>

              <v-btn
                :disabled="i + 1 >= choices.length"
                icon color="primary"
                @click="swapChoices(i, i + 1)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>

              <confirm-button width="400" @click="deleteChoice(i)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    fab depressed small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title style="word-wrap: break-word">
                  {{ $t('pages.week_events.delete_validation_1') }}<br/>
                  {{ $t('pages.week_events.delete_validation_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.week_events.delete_validation_message') }}
                </template>
              </confirm-button>
            </template>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
      </v-scroll-x-transition>
    </v-list>

    <v-row no-gutters>
      <v-spacer/>
      <v-btn fab depressed small color="primary" @click="addChoice">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import ConfirmButton from '@/components/util/ConfirmButton.vue';

export default {
  name: 'choice-collection',
  components: { ConfirmButton },
  props: {
    choices: { type: Array, required: true },
  },
  data: () => ({
    keys: null,
    pagination: null,
  }),
  methods: {
    addChoice() {
      const choices = [...this.choices, ''];

      if (this.keys.length === 0) {
        this.keys = [0];
      } else {
        this.keys.push(this.keys.reduce((acc, v) => Math.max(acc, v)) + 1);
      }

      this.updateChoices(choices);
    },
    deleteChoice(i) {
      const choices = [...this.choices.slice(0, i), ...this.choices.slice(i + 1)];
      this.keys = [...this.keys.slice(0, i), ...this.keys.slice(i + 1)];

      this.updateChoices(choices);
    },
    swapChoices(i, j) {
      const choices = [...this.choices];
      const tmp = choices[i];
      choices[i] = choices[j];
      choices[j] = tmp;

      this.swapKeys(i, j);

      this.updateChoices(choices);
    },
    swapKeys(i, j) {
      const tmp = this.keys[i];
      this.keys[i] = this.keys[j];
      this.keys[j] = tmp;
    },
    updateChoice(i, choice) {
      const choices = [...this.choices];
      choices[i] = choice;

      this.updateChoices(choices);
    },
    updateChoices(value) {
      this.$emit('update', value);
    },
  },
  created() {
    this.keys = [...this.choices.keys()];
  },
};
</script>
