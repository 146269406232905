<template>
  <v-card flat>
    <v-card-title class="text-h4">
      {{ $t('pages.choice_collections.title') }}
    </v-card-title>
    <choice-collection-form @created="addChoiceCollection" :with-choices="false"/>

    <v-card-text class="px-0">
      <v-card flat :loading="fetchMixin.state === fetchMixin.STATES.LOADING">
        <v-card-title class="text-h5">
          {{ $t('pages.choice_collections.list_title') }}
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-center">{{ $t('common.labels.id') }}</th>
              <th width="60%">{{ $t('common.labels.name') }}</th>
              <th class="text-right"></th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="collection in collections" :key="collection.id"
              @click="updateCollection(collection)"
            >
              <td class="text-center">{{ collection.id }}</td>
              <td>{{ collection.name }}</td>
              <td class="text-right">
                <v-btn icon @click.stop="updateCollection(collection)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <confirm-button width="400px" @click="remove(collection.id)">
                  <template v-slot:button="{ on, attrs }">
                    <v-btn
                      icon color="error" v-on="on" v-bind="attrs"
                      :loading="fetchMixin.state === fetchMixin.STATES.DELETING"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:title>
                    Voulez-vous vraiment supprimer cette collection de choix ?
                  </template>

                  <template v-slot:message>
                    La collection sera définitivement supprimée. Cette action est irréversible.
                  </template>
                </confirm-button>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-text v-if="fetchMixin.pagination">
          <paginator
            v-if="fetchMixin.pagination"
            class="pr-4"
            :pagination="fetchMixin.pagination"
            @update="updatePagination"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import Paginator from '@/components/util/paginator.vue';
import ChoiceCollectionForm from '@/components/choicecollection/ChoiceCollectionSaveForm.vue';
import FetchMixin from '@/mixins/fech.mixin';
import DeleteMixin from '@/mixins/delete.mixin';
import Utils from '@/utils/utils';
import ChoiceCollectionService from '@/services/api/choice-collection.service';

export default {
  name: 'choice-collections',
  components: {
    Paginator, ConfirmButton, ChoiceCollectionForm,
  },
  mixins: [FetchMixin, DeleteMixin],
  data: () => ({
    collections: [],
  }),
  methods: {
    addChoiceCollection() {
      this.fetch();
    },
    removeFromList(id) {
      this.collections = this.collections.filter((collection) => collection.id !== id);
    },
    updateCollection(collection) {
      this.$router.push({
        name: 'collection-info',
        params: {
          collectionId: collection.id,
        },
      });
    },
    deleteCollection(id) {
      return ChoiceCollectionService.deleteChoiceCollection(id);
    },
    fetchCollections() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      Utils.scrollToTop();
      return ChoiceCollectionService.fetchChoiceCollections({ page, size });
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'collections',
      fetch: 'fetchCollections',
    };
    this.deleteMixin.config = {
      delete: 'deleteCollection',
      callback: 'removeFromList',
    };
    this.fetch();
  },
};
</script>
