<template>
  <v-card-text class="px-0">
    <v-card flat outlined>
      <v-card-title v-if="isInCreationMode">
        {{ $t('common.action.add') }} {{ $t('common.messages.a_new_fem') }} {{ $t('pages.choice_collection_info.name') }}
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-text-field
            v-bind:label="$t('common.labels.name')"
            v-model="model.name"
            :rules="[(v) => (v != null && v !== '') || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mname')})]"
            @keypress.enter.prevent="create"
          />

          <v-card-text v-if="withChoices">
            <v-row class="text-h6">
              {{ $t('pages.choice_collection_info.choice') }}
            </v-row>

            <v-row class="mb-6">
              <choice-collection :choices="model.choices" @update="updateChoices"/>
            </v-row>
          </v-card-text>

          <v-row no-gutters>
            <v-spacer/>
            <v-btn depressed color="primary" @click="submit"
                   :loading="createMixin.state === createMixin.STATES.CREATING"
                   :disabled="!validForm">
              <span v-if="isInCreationMode">{{ $t('common.action.create') }}</span>
              <span v-else>{{ $t('common.action.modify') }}</span>
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import ChoiceCollectionService from '@/services/api/choice-collection.service';
import ChoiceCollection from '@/components/choicecollection/ChoiceCollection.vue';

export default {
  name: 'choice-collection-form',
  components: { ChoiceCollection },
  mixins: [CreateMixin, UpdateMixin],
  props: {
    value: {
      type: Object,
    },
    withChoices: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    model: {},
    validForm: false,
  }),
  watch: {
    value() {
      this.model = { ...this.value };
    },
  },
  methods: {
    updateChoices(choices) {
      this.$set(this.model, 'choices', choices);
      this.updateChoiceCollection();
    },
    submit() {
      this.$refs.form.validate();
      if (!this.validForm) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    createChoiceCollection() {
      if (this.model.choices === undefined) {
        this.model.choices = [];
      }
      return ChoiceCollectionService.createChoiceCollection(this.model);
    },
    updateChoiceCollection() {
      const { id, ...rest } = this.model;
      return ChoiceCollectionService.updateChoiceCollection(id, rest);
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createChoiceCollection',
    };
    this.updateMixin.config = {
      update: 'updateChoiceCollection',
    };
    this.model = { ...this.value };
  },
};
</script>

<style scoped>

</style>
